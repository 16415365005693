import { Routes, RouterModule } from '@angular/router';
import { MotivosListComponent } from './motivos-list/motivos-list.component';
import { NgModule } from '@angular/core';

const exampleRoutes: Routes = [
  {
    path: '',
    component: MotivosListComponent,
    data: {
      pageTitle: 'Catálogo de Motivos de Rechazo'
    }
  },
];

@NgModule({
  imports: [RouterModule.forChild(exampleRoutes)],
  exports: [RouterModule]
})

export class MotivosRechazoRoutingModule { }
