import { Injectable } from "@angular/core";

@Injectable()
export class ProcesamientoPlantillaHtml {

  private OPTIONS = {
    margin:       0,
    filename:     'resolutivo.pdf',
    image:        { type: 'jpeg', quality: 0.98 },
    html2canvas:  { scale: 2 },
    jsPDF:        { unit: 'mm', format: 'letter', orientation: 'portrait' },
    pagebreak:    { mode: ['avoid-all', 'css', 'legacy'] }
  };

  constructor() {}

  procesar(html: string, tamano: string, aplicarEstiloPrincipal = true, margen = 48) {
    const contenido = html.split('\n').join('');

    const container = document.createElement('div');
    container.style.width = '190.5mm';
    container.innerHTML = contenido;
    container.childNodes.forEach((node1: any) => {
      if (node1.nodeName === 'TABLE') {
        let anchoTable = 0;
        let manejaPorcentaje = false;

        node1.childNodes.forEach(node2 => {
          if (node2.nodeName === 'TBODY') {
            if (node2.firstChild.nodeName  === 'TR') {
              const node3 = node2.firstChild;

              node3.childNodes.forEach(node4 => {
                if (node4.nodeName === 'TD') {
                  const estilo: string = node4.style.width;
                  if (estilo.includes('px')) {
                    const estiloAncho = +estilo.replace('px', '');
                    if (!isNaN(estiloAncho)) {
                      anchoTable += (+estilo.replace('px', ''));
                    }
                  } else {
                    manejaPorcentaje = true;
                  }
                }
              });
  
              if (!manejaPorcentaje) {
                node3.childNodes.forEach(node4 => {
                  if (node4.nodeName === 'TD') {
                    const estilo: string = node4.style.width;
                    const estiloAncho = +estilo.replace('px', '');
                    if (!isNaN(estiloAncho)) {
                      const ancho: number = (estiloAncho * 100) / anchoTable;
                      node4.style.width = ancho + '%';
                    }
                  }
                });
              }
            }
          }
        });

        if (!manejaPorcentaje)
          node1.style.width = '100%';
      }
    });
    document.body.appendChild(container);

    return new Promise<HTMLDivElement[]>((resolve) => {
      setTimeout(() => {
        // Crear páginas
      const altoPagina = +tamano; // Altura en mm menos márgenes
      const pages = [];
      let currentPage = document.createElement('div');
      if (aplicarEstiloPrincipal)
        currentPage.classList.add('page_plantilla_html');
      currentPage.innerHTML = '<div class="content_plantilla_html"></div>';
      pages.push(currentPage);
  
      let altoActual = 0;
      let altoAnterior = 0;
      const nodosHijos = Array.from(container.childNodes);
  
      let contenidoPagina = null;
      let ultimoContenido = null;
      nodosHijos.forEach((nodoHijo:any) => {
        contenidoPagina = currentPage.querySelector('.content_plantilla_html');
        if (!contenidoPagina) {  
          contenidoPagina = ultimoContenido;
        }
  
        // nodoHijo.localName === 'ul'
  
        altoAnterior = altoActual;
        if (nodoHijo.offsetHeight)
          altoActual += nodoHijo.offsetHeight;
        if (altoActual > altoPagina) {
          let crearPagina = true;
          // if (((altoActual - altoPagina) <= +margen) && (nodoHijo.offsetHeight <=  +margen)) {
          //   if (nodoHijo.innerHTML && !nodoHijo.innerHTML.includes('<img')) {
          //     crearPagina = false;
          //   }
          // }
          // if (nodoHijo.localName === 'table') {
            
          // }
          
          if (nodoHijo.localName === 'ul' && (altoActual - altoPagina) > 20) {
            let cloneHijo = nodoHijo.cloneNode(true);
            cloneHijo.innerHTML = '';
  
            const listaNodosFaltantes: any[] = [];
            nodoHijo.childNodes.forEach((li: any) => {
              if (li.offsetHeight)
                altoAnterior += li.offsetHeight;
              if (altoAnterior < altoPagina) {
                cloneHijo.appendChild(li.cloneNode(true));
              } else {
                listaNodosFaltantes.push(li);
              }
            });
  
            contenidoPagina.appendChild(cloneHijo.cloneNode(true));
  
            while (nodoHijo.firstChild) nodoHijo.removeChild(nodoHijo.lastChild);
            listaNodosFaltantes.forEach(nodo => {
              nodoHijo.appendChild(nodo);
            });
          }
  
          if (crearPagina) {
            // Crear nueva página
            currentPage = document.createElement('div');
            if (aplicarEstiloPrincipal)
              currentPage.classList.add('page_plantilla_html');
            currentPage.innerHTML = '<div class="content_plantilla_html"></div>';
            pages.push(currentPage);
            contenidoPagina = currentPage.querySelector('.content_plantilla_html');
            altoActual = nodoHijo.offsetHeight; // Resetear altura
          }
  
          if (nodoHijo.innerHTML.startsWith('<span') && nodoHijo.childNodes.length > 0 
            && nodoHijo.firstChild.innerHTML == "&nbsp;")
            altoActual = 0; 
          else 
            contenidoPagina.appendChild(nodoHijo.cloneNode(true));  
        } else {
          contenidoPagina.appendChild(nodoHijo.cloneNode(true));
        }
  
        ultimoContenido = contenidoPagina;
      });
  
      // Limpiar contenedor temporal
      container.remove();
  
      resolve(pages);
      }, 1000);
    });
  }

  agregarPiePagina(paginas: HTMLDivElement[], texto: string, imagen: string, tamano_alto: number, tamano_letra: number, alineacion: number) {
    if (!texto && !imagen) return paginas;
    
    alineacion = alineacion ? alineacion : 0;
    const estiloAlineacion = ['center', 'flex-start', 'flex-end', 'center'][alineacion];
    tamano_alto = tamano_alto ? tamano_alto : 1.5;
    tamano_letra = tamano_letra ? tamano_letra: 12;
    const fondoImagen = imagen ? `style="justify-content: ${estiloAlineacion}; height:${tamano_alto}cm; font-size:${tamano_letra}px; background-image:url('${imagen}'); background-size: cover; background-repeat: no-repeat;"` : '';
    const textoPiePagina = texto ? texto : '';
    const margenTexto = ['center', 'margin-left: 10px;', 'margin-right: 10px;', 'center'][alineacion];
    

    let noPiePagina = 1;
    paginas.forEach(pagina => {
      const textoProcesado = `<span style="${margenTexto}">` + textoPiePagina.replace('|PIE_PAGINA|', noPiePagina + '').replace('|pie_pagina|', noPiePagina + '') + '</span>';
      pagina.innerHTML += `<div class="footer_plantilla_html" ${fondoImagen}>${textoProcesado}</div>`;
      noPiePagina++;
    });

    return paginas;
  }

  agregarEncabezado(paginas: HTMLDivElement[], texto: string, imagen: string, tamano_alto: number, tamano_letra: number, alineacion: number) {
    if (!texto && !imagen) return paginas;

    alineacion = alineacion ? alineacion : 0;
    const estiloAlineacion = ['center', 'flex-start', 'flex-end', 'center'][alineacion];
    tamano_alto = tamano_alto ? tamano_alto : 1.5;
    tamano_letra = tamano_letra ? tamano_letra: 12;
    const fondoImagen = imagen ? `style="justify-content: ${estiloAlineacion}; margin-right: 3px; height:${tamano_alto}cm; font-size:${tamano_letra}px; background-image:url('${imagen}'); background-size: cover; background-repeat: no-repeat;"` : '';
    const textoEncabezado = texto ? texto : '';
    const margenTexto = ['center', 'margin-left: 10px;', 'margin-right: 10px;', 'center'][alineacion];

    let noPiePagina = 1;
    paginas.forEach(pagina => {
      const textoProcesado = `<span style="${margenTexto}">` + textoEncabezado.replace('|PIE_PAGINA|', noPiePagina + '').replace('|pie_pagina|', noPiePagina + '') + '</span>';
      const contenido = pagina.innerHTML;
      pagina.innerHTML = `<div class="header_plantilla_html" ${fondoImagen}>${textoProcesado}</div>` + contenido;
      noPiePagina++;
    });

    return paginas;
  }

  agregarMarcaAgua(paginas: HTMLDivElement[], imagen: string, posicionAlto: number, posicionAncho: number, alineacion: number) {
    if (!imagen) return paginas;

    alineacion = alineacion ? alineacion : 0;
    let valorAlineacion = ['top', 'left', 'right', 'center'][alineacion];
    let alineamiento = '';
    if (valorAlineacion === 'top') {
      alineamiento = 'top ';
      if (posicionAlto && !posicionAncho) {
        alineamiento += posicionAlto + 'cm' + ' center';
      } else if (!posicionAlto && posicionAncho) {
        alineamiento += 'left ' + posicionAncho + 'cm';
      } else if (posicionAlto && posicionAncho) {
        alineamiento += posicionAlto + 'cm'  + ' left ' + posicionAncho + 'cm';
      }
    } else if (valorAlineacion === 'left') {
      alineamiento = 'left ';
      if (posicionAncho && !posicionAlto) {
        alineamiento += posicionAncho + 'cm';
      } else if (!posicionAncho && posicionAlto) {
        alineamiento += 'top ' + posicionAlto + 'cm';
      } else if (posicionAncho && posicionAlto) {
        alineamiento += posicionAncho + 'cm'  + ' top ' + posicionAlto + 'cm';
      } else {
        alineamiento += ' top 0cm';
      }
    } else if (valorAlineacion === 'right') {
      alineamiento = 'right ';
      if (posicionAncho && !posicionAlto) {
        alineamiento += posicionAncho + 'cm top 0cm';
      } else if (!posicionAncho && posicionAlto) {
        alineamiento += 'top ' + posicionAlto + 'cm';
      } else if (posicionAncho && posicionAlto) {
        alineamiento += posicionAncho + 'cm'  + ' top ' + posicionAlto + 'cm';
      } else {
        alineamiento += ' top 0cm';
      }
    } else if (valorAlineacion === 'center') {
      alineamiento = 'center ';
      if (posicionAlto && !posicionAncho) {
        alineamiento += posicionAlto + 'cm';
      } else if (!posicionAlto && posicionAncho) {
        alineamiento += 'left ' + posicionAncho + 'cm';
      } else if (posicionAlto && posicionAncho) {
        alineamiento += posicionAlto + 'cm'  + ' left ' + posicionAncho + 'cm';
      }
    }

    paginas.forEach(pagina => {
      pagina.innerHTML = `<div class="page_plantilla_html" style="background-image: url('` + imagen + `'); background-position: ${alineamiento}; background-repeat: no-repeat; background-attachment: fixed;">` + pagina.innerHTML + '</div>';
    });

    return paginas;
  }
}