import { Injectable } from '@angular/core';
import { MotivosModel } from './motivos-rechazo.model';
import { MotivoRechazoResource } from './motivos-rechazo.resource';

@Injectable()
export class MotivosService {

  constructor(private motivosResource: MotivoRechazoResource) {}

  getAllMotivosRechazo(): Promise<MotivosModel[]> {
    return this.motivosResource.getAll();
  }

  getAllMotivosRechazoFilterByIdDocumento(id: number): Promise<MotivosModel[]>{
    return this.motivosResource.getAllByDocument({id: id});
  }

  getAllMotivosRechazoByIdTramite(tramite_id: string): Promise<MotivosModel[]>{
    return this.motivosResource.getAllByRejectionByTramite({tramite_id});
  }

  getOneMotivoRechazo(id: number): Promise<MotivosModel> {
    return this.motivosResource.getOne({id: id});
  }

  saveMotivoRechazo(motivoRechazo: MotivosModel): Promise<any> {
    return this.motivosResource.save(motivoRechazo);
  }

  updateMotivoRechazo(motivoRechazo: MotivosModel): Promise<any> {
    return this.motivosResource.update(motivoRechazo);
  }

  deleteMotivoRechazo(id: number) {
    return this.motivosResource.deleteMotivo({id: id});
  }

}
