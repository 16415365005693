/**
 * Created by orojo on 09/10/2018.
 */

import {Injectable} from '@angular/core';
import {RequirementsResource} from '../service/requirements.resource';
import {RequirementsModel} from '../service/requirements.model';
import {FormalityConfigModel} from '../service/formality-config.model';

@Injectable()
export class RequirementsService {

  constructor(private requirementsResource: RequirementsResource) {
  }

  getAllRequiriments(): Promise<any[]> {
    return this.requirementsResource.getRequirements();
  }

  deleteRequirement(id: number): Promise<any> {
    return this.requirementsResource.deleteRequirement({id: id});
  }

  getAllFormalities(): Promise<FormalityConfigModel[]> {
    return this.requirementsResource.getFormalities();
  }

  getOneRequeriment(id: number): Promise<RequirementsModel> {
    return this.requirementsResource.getRequirement({id: id});
  }

  // getAllRequirimentsEnable(id: string): Promise<RequirementsModel[]> {
  //   return this.requirementsResource.getRequirementEnable({id: id});
  // }
   
  getAllDocumentos(id: string): Promise<any[]> {
    return this.requirementsResource.getDocumentos({id: id});
  }

  saveRequirements(requirement: RequirementsModel) {
    return this.requirementsResource.save(requirement);
  }

  updateRequirements(requirement: RequirementsModel) {
    return this.requirementsResource.update(requirement);
  }
}
