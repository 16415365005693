/**
 * Created by Raul on 02/06/2018.
 */
import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {IOption, ToastService, UploadFile, UploadInput, UploadOutput} from 'ng-uikit-pro-standard';
import {RequirementsUploadModel} from './service/requirement-helper.model';
import {isUndefined} from 'util';
import {CaseFileModel} from './service/case-file-uploaded.model';
import {DownloadHelper} from '../../util/download-helper.service';
import {AuthServerProvider} from '../../auth/auth-jwt.service';
import {RfcService} from '../../service/rfc.service';
import {DataShareService} from '../../util/data.share';
import {ExpedienteArchivosModel} from '../../../admin.consulta-exp-elec.module/service/expediente-archivos.model';
import {RequisitosService} from '../../../admin.consulta-exp-elec.module/service/requisitos.service';
import {CampoAdicional} from '../../../common.tramite.module/entidad.component/service/CampoAdicional.model';
import {ProviderFormatComponent} from './visor/provider-pdf-format.component';
import {Router} from '@angular/router';
import {VentanillaUnicaService} from "../../service/ventanilla-unica.service";
import {BpmEstadoProcesoDetalleService} from "../../../common.tramite-vu.module/entidad.formulario.component/service/bpm-estado-proceso-detalle.service";
import { environment } from 'src/environments/environment';
import { DownloadFileService } from './service/download-file.service';
import * as FileSaver from 'file-saver';
import { DomSanitizer } from '@angular/platform-browser';
import { RequirementModel } from '../../model/requirement.model';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MotivosModel } from '../../../admin.motivos-rechazo.module/service/motivos-rechazo.model';

@Component({
  selector: 'app-upload-requirements',
  templateUrl: 'upload-requirements.component.html'
})
export class UploadRequirementsComponent implements OnInit {
  @ViewChild('DescargandoModal') modalDescargando;
  public empiezaDescarga =  false;

  constructor(private toastMsg: ToastService, private auth: AuthServerProvider, private delService: RfcService,
              private dataShare: DataShareService, private requisitosService: RequisitosService, private router: Router,
              private bpmEstadoProcesoDetalleService: BpmEstadoProcesoDetalleService,
              private fileService: DownloadFileService,private _sanitizer: DomSanitizer) {
  }
  public file: UploadFile = <UploadFile>{};
  public fileModel: any;
  public files: UploadFile[] = [];
  @ViewChild('visor')
  public visor: ProviderFormatComponent;
  public banIndexRequirement: number = undefined;
  @Input()
  public frm_datos_adicionales: CampoAdicional[];
  @Input()
  public main_action = 1;
  @Input()
  public fromExpediente = false;
  @Input()
  public showReq: boolean;
  @Input()
  public requirements: RequirementsUploadModel[] = [];
  @Input()
  public pathUploadUrl: string;
  @Input()
  public caseFile: CaseFileModel[] = [];
  @Input()
  public isDownloaded = false;
  @Input()
  public noAuth = false;
  @Input()
  public disDel = true;
  @Input()
  public disAprv = false;
  @Input()
  public estatus_proceso = true;
  @Input()
  public disCiudadanoAprv = false;
  @Input()
  public validity: boolean;
  public openV: string;
  @Input()
  public idReq: any;
  @Input()
  enabled: boolean;
  @Input()
  folio: string;
  // Variables para controlar los botones de aprovacion en Ventanilla unica
  @Input()
  public showBtnObservations = false;
  @Input()
  public showBtnApproval = false;
  @Input()
  public disableApproval = true; 
  @Input()
  public showBtnDownload = false;
  @Input()
  public esPortal = true;
  @Input()
  public listReasonRejections: MotivosModel[] = [];
  public listReasonRejectionsFiltered: IOption[] = [];
  reasonForRejectionFrm: FormGroup;
  @Output()
  public openValidity: EventEmitter<any> = new EventEmitter();
  @Output()
  public openObservacion: EventEmitter<any> = new EventEmitter();
  @Output()
  public deleteRequirement: EventEmitter<any> = new EventEmitter();
  @Output()
  public sendSaveSignal: EventEmitter<any> = new EventEmitter();
  public outputImage: UploadOutput = <UploadOutput>{};
  public idDocto: any;
  public banVisorOp: boolean ;
  public bandElimina: boolean;
  public valorInput: any;

  /**
   * Objeto que evita que se puedan guardar archivos antes de que estos sean cargados en el sistema
   * Cambia a 1 cuando se está cargando algún archivo y a 0 cuando se terminó de cargar
   */
  @Input()
  public manageLoading = {loadCount: 0};
  /**
   * Nos dice si la variable anterior se tomará en cuenta en el proceso de carga de archivos,
   * si es true se toma en cuenta, si es falso se ignora
   */
  @Input()
  blockLoading = true;


  public uploadInput = new EventEmitter<UploadInput>();

  ngOnInit() {
    console.log(this.enabled);
    this.banVisorOp = false;

    this.reasonForRejectionFrm = new FormGroup({
      reason: new FormControl('', Validators.required),
      description: new FormControl('')
    });
  }

  /*private ordenarObligatoriosPrimero(requerimientosOriginal: RequirementsUploadModel[]) {
    let requerimientosNoObligatorios: RequirementsUploadModel[] = [];
    requerimientosOriginal.forEach(requerimiento => {
      if (!requerimiento.requirement.required) {
        // Si no es obligatorio, se quita del array original y se reserva (para agregarlo al final)
        const index = requerimientosOriginal.indexOf(requerimiento, 0);
        if (index > -1) {
          requerimientosOriginal.splice(index, 1);
          requerimientosNoObligatorios.push(requerimiento);
        }
      }
    });
    // Agrega los requerimientos no obligatorios al final del array.
    requerimientosOriginal.push(...requerimientosNoObligatorios);
  }*/

  showFiles() {
    return (this.file && this.file.name) ? this.file.name : '';
  }

  startUpload(): void {
    let headers = {};
    if (!this.noAuth)
      headers = {'Authorization': 'Bearer ' + this.auth.getToken()};
    const event: UploadInput = {
      type: 'uploadFile',
      url: this.pathUploadUrl,
      headers: headers,
      method: 'POST',
      file: this.file,
    };
    setTimeout(() => {
      this.uploadInput.emit(event);
    }, 1000);
  }

  onUploadRequirement(output: UploadOutput | any): void {
    const limiteCargaArchivo = 200000000;
    const limiteCargaArchivoPDF = 10000000;
    this.file = output.file;

    if(this.file) {
      if(this.pathUploadUrl.search('core') === -1 ){
        if(this.file.nativeFile.type == 'application/x-zip-compressed' || this.file.nativeFile.type == 'application/zip') {
          //this.pathUploadUrl = 'http://localhost:8006/upldFilesSistema';
          this.pathUploadUrl = environment.apiBaseUrl + '/api/ven-unica/upldFilesSistema';
          if(this.file.nativeFile.size >= limiteCargaArchivo) { // Menor a 600MB
            this.toastMsg.error('El archivo debe de ser menor a 200 MB');
            return;
          }
        } else {

          if(this.file.nativeFile.type == 'application/pdf')
          {
            this.pathUploadUrl = environment.apiBaseUrl + '/api/ven-unica/upldFiles';
            //this.pathUploadUrl = 'http://localhost:8006/upldFiles';
            if(this.file.nativeFile.size >= limiteCargaArchivoPDF) { // Menor a 600MB
              this.toastMsg.error('El archivo debe de ser menor a 10 MB');
              return;
            }
          }else{
            this.toastMsg.error('Formato de archivo no aceptado.');
            return;
          }
          
        }

      }
      console.log(output);

      this.banVisorOp = true;
      if (output.type === 'addedToQueue') {
        if (isUndefined(this.banIndexRequirement)) {
          this.toastMsg.warning('Favor de seleccionar el requisito a subir.');
          return;
        }

        this.toastMsg.info('El archivo se esta cargando . . .');
        this.showFiles();
        this.startUpload();
        if (this.blockLoading)
          this.manageLoading.loadCount ++;
      } else if (output.type === 'done') {
        this.toastMsg.success('El archivo se cargó correctamente');
        console.log(output);
        console.log('Valor base 64: ' + output.file.response.base64);
        this.requirements[this.banIndexRequirement].requirement.cargaCompleta = true;
        this.banVisorOp = false;
        if (!output.file && !output.file.response) {
          this.banVisorOp = false;
          this.toastMsg.error('El requerimiento no se pudo subir, intente más trade.');
          return;
        } else if (output.file.response.message) {
          this.toastMsg.error(output.file.response.message);
          console.log('Valor base 64: ' + output.file.response.base64);
          if (this.manageLoading.loadCount > 0) {
            this.manageLoading.loadCount--;
            this.file.name = '';
          }
          return;
        } else if (output.file.response.error && output.file.response.error == 'bad_gateway') {
          this.toastMsg.error('Formato de archivo no aceptado');
          if (this.manageLoading.loadCount > 0) {
            this.manageLoading.loadCount--;
            this.file.name = '';
          }
          return;
        }
        if (output.file.type === 'application/x-zip-compressed') {
          output.file.type = 'application/zip';
        }
        this.requirements[this.banIndexRequirement].uploadFile = output.file;
        if (this.blockLoading)
          this.manageLoading.loadCount --;
          if (output.file.type === 'application/x-zip-compressed' || output.file.type === 'application/zip') {
            this.visor.fileZip();
          } else {
            this.visor.downloadFile(output.file.response.base64);
          }
        delete this.valorInput;
      }
      if (output.type === 'uploadFile') {
        console.log('OUTPUT ', output);
      }

      this.requirements[this.banIndexRequirement].requirement.progreso = this.file.progress.data.percentage;
      console.log('managemenLoading ', this.manageLoading);
      console.log(output);
    }


  }

 /* public escucharPadre(padre, condicion): boolean {
    console.log('condicion');
    console.log(condicion);
    let result = false;
    if (padre != null && condicion != null) {
      this.frm_datos_adicionales.forEach(dato => {
        console.log('condicion: ');
        console.log(condicion);
        if (dato.tipo_datos_nombre == 'select' && dato.nombre_atributo == padre && (condicion.indexOf(dato.valor_usuario) > -1)) {
          result = true;
        } else if (dato.tipo_datos_nombre == 'check') {
          const valor_condicion = dato.lista_valores.find(valor => {
            if (condicion.indexOf(valor.clave) > -1) {
              return true;
            } else {
              return false;
            }
          });
          if (valor_condicion != null && !valor_condicion.valor_usuario && dato.nombre_atributo == padre) {
            result = true;
          }
        }
      });
    } else {
      result = true;
    }
    return result;
  }*/
  public escucharPadre(padre, condicion): boolean {
    let result = true;
    if (padre != null && condicion != null) {
      // no se valida contra el padre tengo que tener el dato de el hijo
      this.frm_datos_adicionales.forEach(dato => {
        if (dato.tipo_datos_nombre != 'check' && dato.nombre_atributo == padre && (condicion.indexOf(dato.valor_usuario) > -1)) {
          result = false;
        } else {
          const valor_condicion = dato.lista_valores.find(valor => {
            if (condicion.indexOf(valor.clave) > -1) {
              return true;
            } else {
              return false;
            }
          });
          if (valor_condicion != null && valor_condicion.valor_usuario && dato.nombre_atributo == padre) {
            result = false;
          }
        }
      });
    }
    return result;
  }

  getVigencia(req) {
    let valido = true;
    const archivo_vigencia = req.caseFileUploaded;
    if (req.caseFileUploaded && archivo_vigencia.vigencia && archivo_vigencia.vigencia.length > 0) {
      const date_splited = archivo_vigencia.vigencia.split('/');
      const day = date_splited[0];
      const month = date_splited[1];
      const year = date_splited[2];
      const req_vigencia = new Date(year, month - 1, day);
      if (req_vigencia < new Date()) {
        valido = false;
        this.showReq = false;
        this.sendSaveSignal.emit();
      }
    }
    if (archivo_vigencia && archivo_vigencia.estatus != 'A') {
      valido = false;
      this.showReq = false;
      this.sendSaveSignal.emit();
    }
    if (!archivo_vigencia) {
      valido = false;
      this.showReq = false;
      this.sendSaveSignal.emit();
    }
    return valido;
  }

  selectValidation($event: Event) {
    if (isUndefined(this.banIndexRequirement)) {
      $event.preventDefault();
      this.toastMsg.warning('Favor de seleccionar el requisito a subir.');
    }
  }

  limpiarDatos() {
    console.log('Entramos a limpiar.');
    this.banIndexRequirement = undefined;
    // this.showReq = true;
    this.disDel = true;
    if (this.file && this.file.name) {
      this.file.name = '';
    }
    if (document.getElementById('divVisor')) {
      document.getElementById('divVisor').innerHTML = '';
    }
  }

  downloadRequirementUploaded(req: RequirementsUploadModel) {
    if (!isUndefined(req.requirement) && !isUndefined(req.caseFileUploaded))
      DownloadHelper.downloadFile(req.requirement.requirement_name
        + '.' + req.caseFileUploaded.file_extension, req.caseFileUploaded.case_file);
  }

  deleteRequirementUploaded(reqIndex: number = -1) {
    if (this.idDocto !== '' && this.requirements[reqIndex].uploadFile) {
      if (reqIndex === -1)
        return;
      if (this.requirements[reqIndex]) {
        delete this.requirements[reqIndex].uploadFile;
        delete this.requirements[reqIndex].caseFileUploaded;
        this.requirements[reqIndex].requirement.progreso = null;
        this.requirements[reqIndex].requirement.cargaCompleta = false;
      }
      if (document.getElementById('divVisor')) {
        document.getElementById('divVisor').innerHTML = '';
      }
      this.toastMsg.success('El archivo fue eliminado con éxito');
    } else {
      this.toastMsg.warning('Favor de seleccionar el requisito a eliminar.');
    }
  }

  deleteRequirementUploaded1(reqIndex: number = -1) {
    if (this.requirements[reqIndex].requirement.required) {
      if (reqIndex === -1)
        return;
      if (this.requirements[reqIndex]) {
        delete this.requirements[reqIndex].uploadFile;
        delete this.requirements[reqIndex].caseFileUploaded;
        this.requirements[reqIndex].requirement.progreso = null;
        this.requirements[reqIndex].requirement.cargaCompleta = false;
      }
      if (document.getElementById('divVisor')) {
        document.getElementById('divVisor').innerHTML = '';
      }
      this.toastMsg.success('El archivo fue eliminado con éxito');
    } else {
      if (this.idDocto !== '') {
        if (reqIndex === -1)
          return;
        if (this.requirements[reqIndex]) {
          this.requirements[reqIndex].caseFileUploaded.case_file = '';
          this.requirements[reqIndex].caseFileUploaded.eliminado = true;
          this.bandElimina = true;
          this.requirements[reqIndex].requirement.progreso = null;
          this.requirements[reqIndex].requirement.cargaCompleta = false;
        }
        if (document.getElementById('divVisor')) {
          document.getElementById('divVisor').innerHTML = '';
        }
      } else {
        this.toastMsg.warning('Favor de seleccionar el requisito a eliminar.');
      }
    }

  }

  deleteRequirementSaved(reqIndex: number = -1) {
    if (reqIndex === -1)
      return;
    if (this.requirements[reqIndex]) {
      this.deleteRequirement.emit(this.requirements[reqIndex]);
    }
  }


  openModalValidity(id) {
    console.log('Se emitirá ' + id);
    this.openV = this.requirements[id].caseFileUploaded.id;
    console.log(this.openV);
    this.openValidity.emit(this.openV);
    this.dataShare.objectConfirmed$.subscribe(data => {
      if (data && data['vigencia'])
        this.requirements.map(r => {
          if (r.caseFileUploaded.id == data['id']) {
            r.caseFileUploaded.vigencia = data['vigencia'];
          }
        });
      // this.requirements[id].caseFileUploaded.estatus = data['regreso_de_modal_de_observacion'];
      // return;
    });
    if (id === -1)
      return;
  }


  openModalApprove(id: any, radio: string) {
    if (this.disCiudadanoAprv) {
      if (this.requirements[id].caseFileUploaded.observacion && this.requirements[id].caseFileUploaded.estatus != 'A' && radio == 'V') {
        this.toastMsg.warning(this.requirements[id].caseFileUploaded.observacion, 'Razón de rechazo ');
      }
      return;
    }
    if (radio == 'R') {
      // this.requirements[id].caseFileUploaded.estatus = 'R';
      this.openV = this.requirements[id].caseFileUploaded.id;
      this.openObservacion.emit(this.openV);
      this.dataShare.objectConfirmed$.subscribe(data => {
        if (data && data['regreso_de_modal_de_observacion'])
          this.requirements.map(r => {
            if (r.caseFileUploaded && r.caseFileUploaded.id == data['_id']) {
              r.caseFileUploaded.estatus = data['regreso_de_modal_de_observacion'];
              r.caseFileUploaded.estatus = 'Rechazado por el funcionario';
            }
          });
        // this.requirements[id].caseFileUploaded.estatus = data['regreso_de_modal_de_observacion'];
        // return;
      });
    } else {
      let aux: ExpedienteArchivosModel;
      aux = {
        observacion: '',
        estatus: 'A',
        _id: this.requirements[id].caseFileUploaded.id
      };
      this.requisitosService.actualizarArchivoId(aux).then(res => {
        this.toastMsg.success('El archivo se ha aprobado con éxito');
        this.requirements[id].caseFileUploaded.estatus = 'A';
      }, err => {
        if (err.body) {
          if (err.body.message)
            this.toastMsg.error(err.body.message);
          else
            this.toastMsg.error('Hubo un problema al actualizar el archivo');
        } else {
          this.toastMsg.error('Hubo un error inesperado');
        }
      });
    }
  }




  changeInput(i) {
    this.file = null;
    console.log('id');
    console.log(i);
    console.log(this.requirements[i]);
    this.idDocto = i;
    if (this.requirements[i].caseFileUploaded) {
      if(this.requirements[i].caseFileUploaded.nombre_archivo_sistema) {
        this.visor.fileZip();
      } else {
        console.log('Entre al visor: ' + this.requirements[i].caseFileUploaded.case_file);
        this.visor.downloadFile(this.requirements[i].caseFileUploaded.case_file);
      }
    } else if (this.requirements[i].uploadFile) {
      if(this.requirements[i].uploadFile.response.nombre_archivo_sistema) {
        this.visor.fileZip();
      } else {
        this.visor.downloadFile(this.requirements[i].uploadFile.response.base64);
      }
    } else {
      // $('#divVisor').innerHTML = "";
      if (document.getElementById('divVisor')) {
        document.getElementById('divVisor').innerHTML = '';
      }
    }
  }

  descargaPlantilla(plantilla) {
   if (plantilla && plantilla !== '') {
     this.bpmEstadoProcesoDetalleService.getEstadoProcesoDoc(plantilla).then(resp => {
        this.downloadFile(resp.nombre, resp.archivo, '' );
      }, err => {
        if (err.body) {
          if (err.status == 401) {
            this.toastMsg.error('Debe de iniciar sesión para continuar');
          } else if (err.body.message) {
            this.toastMsg.error(err.body.message);
          } else {
            this.toastMsg.error('Ocurrio un error inesperado, favor de intentarlo más tarde');
          }
        }
      });
    } else {
      this.toastMsg.warning('Debe de seleccionar una plantilla a descargar');
    }
  }
  downloadFile(filename, base64, ext) {
     const dataURI = this.base64ToArrayBuffer(base64);
    if ( (ext === undefined) || (ext === null) || (ext === '')) {
      ext = 'application/docx';
    }
    const blob = new Blob([dataURI], {type: ext});

    if (window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveBlob(blob, filename);
    } else {
      const elem = window.document.createElement('a');
      elem.href = window.URL.createObjectURL(blob);
      elem.download = filename;
      document.body.appendChild(elem);
      elem.click();
      document.body.removeChild(elem);
    }
  }
  base64ToArrayBuffer(base64) {
    const binary_string = window.atob(base64);
    const len = binary_string.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      bytes[i] = binary_string.charCodeAt(i);
    }
    return bytes.buffer;
  }

  descargarZip(req: RequirementsUploadModel) {
    let nombre_archivo_sistema = '';
    this.modalDescargando.show();
    this.empiezaDescarga = false;
    console.log(req);

    let headers = {};
    if (!this.noAuth) {
      headers = {'Authorization': 'Bearer ' + this.auth.getToken()};
    }

    if (req.caseFileUploaded && req.caseFileUploaded.nombre_archivo_sistema) {
      nombre_archivo_sistema = req.caseFileUploaded.nombre_archivo_sistema;
    } else if (req.uploadFile.response && req.uploadFile.response.nombre_archivo_sistema) {
      nombre_archivo_sistema = req.uploadFile.response.nombre_archivo_sistema
    }

    this.fileService.downloadFile(nombre_archivo_sistema, headers).subscribe(response => {
      console.log(response);
      this.empiezaDescarga = true;
      this.modalDescargando.hide();
	//window.open(response.url);
      const data: Blob = new Blob([response.blob()], {
        type: '.zip'
      });
      const nombreArchivo = req.uploadFile.name+'_'+this.folio+'.zip';
      FileSaver.saveAs(data, nombreArchivo);
		}), error => console.log('Error al descargar-->'+error),
                 () => console.info('Se descargó correctamente');

  }

  descargarZipTmp(requirement_name: string, nombre_archivo_sistema: string) {
    this.modalDescargando.show();
    this.empiezaDescarga = false;

    let headers = {};
    if (!this.noAuth) {
      headers = {'Authorization': 'Bearer ' + this.auth.getToken()};
    }

    this.fileService.downloadFileTmp(nombre_archivo_sistema, headers).subscribe(response => {
      this.empiezaDescarga = true;
      this.modalDescargando.hide();
	//window.open(response.url);
      const data: Blob = new Blob([response.blob()], {
        type: '.zip'
      });
      const nombreArchivo = requirement_name+'.zip';
      FileSaver.saveAs(data, nombreArchivo);
		}), error => console.log('Error al descargar-->'+error),
    () => console.info('Se descargó correctamente');
  }

  downloadFileLoad(i: number) {
    if (this.requirements[i].caseFileUploaded) {
      if(this.requirements[i].caseFileUploaded.nombre_archivo_sistema) {
        this.descargarZip(this.requirements[i]);
      } else {
        this.downloadRequirementUploaded(this.requirements[i]);
      }
    } else if (this.requirements[i].uploadFile) {
      if(this.requirements[i].uploadFile.response.nombre_archivo_sistema) {
        this.descargarZipTmp(this.requirements[i].requirement.requirement_name, this.requirements[i].uploadFile.response.nombre_archivo_sistema);
      } else {
        this.downloadFile(this.requirements[i].uploadFile.name, this.requirements[i].uploadFile.response.base64, 'pdf')
      }
    } else {
      this.toastMsg.info("No se ha cargado ningún archivo");
    }
  }

  actualizarStatusReq(resp: boolean) {
    if (resp) {
      this.requirements[this.idDocto].requirement.motivo_rechazo_id = this.reasonForRejectionFrm.controls["reason"].value;
    }

    this.reasonForRejectionFrm.reset();
  }

  approveReq(event: any, i: any, modal: any) {
    console.log(event);
    if (!event.checked) {
      this.seeObservations(i, modal);
    } else {
      this.requirements[i].requirement.motivo_rechazo_id = '';
    }
  }

  seeObservations(i: any, modal: any) {
    this.idDocto = i;    

    this.listReasonRejectionsFiltered = this.listReasonRejections
      .filter(reason => {
        return reason.nombre_documento === this.requirements[i].requirement.requirement_name;
      })
      .map(reason => {
        return {
          value: reason._id,
          label: reason.descripcion,
          icon: ''
        }
      });

    if (this.requirements[i].requirement.motivo_rechazo_id !== undefined && this.requirements[i].requirement.motivo_rechazo_id !== '') {
      this.reasonForRejectionFrm.get('reason').setValue(this.requirements[i].requirement.motivo_rechazo_id);

      const reason = this.listReasonRejections.find(r => r._id === this.requirements[i].requirement.motivo_rechazo_id);
      if (reason) {
        this.reasonForRejectionFrm.get('description').setValue(reason.descripcion);
      }
    }

    modal.show();
  }

}
