/**
 * Created by OROJO on 10/01/2019.
 */

import {Injectable} from '@angular/core';
import {IResourceMethod, Resource, ResourceAction, ResourceHandler, ResourceParams, ResourceRequestMethod} from '@ngx-resource/core';
import {FormalitiesConfigModel} from './formalitiesConfig.model';
import {CategoryModel} from './category.model';
import {ResourceAuth} from '../../common.module/auth/authenticate.resource';
import {AuthServerProvider} from '../../common.module/auth/auth-jwt.service';

@Injectable()
@ResourceParams({
  pathPrefix: '/coremicro'
  // url: 'http://localhost:8022'
})

export class FormalitiesResource extends ResourceAuth {
  constructor(restHandler: ResourceHandler, authProv: AuthServerProvider) {
    super(restHandler, authProv);
  }
// export class FormalitiesResource extends Resource {
//   constructor(restHandler: ResourceHandler) {
//     super(restHandler);
//   }

  @ResourceAction({
    path: '/formalities'
  })
  getFormalites: IResourceMethod<any, any[]>;

  @ResourceAction({
    path: '/formalities/detail/{!id}'
  })
  getOneFormalitie: IResourceMethod<{ id: any }, FormalitiesConfigModel>;

  @ResourceAction({
    path: '/formalities',
    method: ResourceRequestMethod.Post
  })
  save: IResourceMethod<FormalitiesConfigModel, FormalitiesConfigModel>;

  @ResourceAction({
    path: '/formalities',
    method: ResourceRequestMethod.Patch
  })
  update: IResourceMethod<FormalitiesConfigModel, FormalitiesConfigModel>;

  @ResourceAction({
    path: '/allcategories',
  })
  getCategories: IResourceMethod<any, CategoryModel[]>;
}
