/**
 * Created by orojo on 09/10/2018.
 */
import {Injectable} from '@angular/core';
import {IResourceMethod, Resource, ResourceAction, ResourceHandler, ResourceParams, ResourceRequestMethod} from '@ngx-resource/core';
import {ResourceAuth} from '../../common.module/auth/authenticate.resource';
import {RequirementsModel} from '../service/requirements.model';
import {FormalityConfigModel} from '../service/formality-config.model';
import {AuthServerProvider} from '../../common.module/auth/auth-jwt.service';

@Injectable()
@ResourceParams({
  // url: 'http://localhost:8022'
  pathPrefix: '/coremicro'
})
// export class RequirementsResource extends Resource {
export class RequirementsResource extends ResourceAuth {
  // constructor(handler: ResourceHandler) {
  //   super(handler);
  // }

  constructor(handler: ResourceHandler, auth: AuthServerProvider) {
    super(handler, auth);
  }

  @ResourceAction({
    path: '/requirements'
  })
  getRequirements: IResourceMethod<any, RequirementsModel[]>;

  @ResourceAction({
    path: '/requirements/delete/{!id}',
    method: ResourceRequestMethod.Delete
  })
  deleteRequirement: IResourceMethod<{ id: any }, any>;

  @ResourceAction({
    path: '/formalities'
  })
  getFormalities: IResourceMethod<any, FormalityConfigModel[]>;

  @ResourceAction({
    path: '/requirements/{!id}'
  })
  getRequirement: IResourceMethod<{ id: any }, RequirementsModel>;

  @ResourceAction({
    path: '/documentos_por_tramite_id/{!id}'
  })
  getDocumentos: IResourceMethod<{ id: any }, any[]>;

  @ResourceAction({
    path: '/requirements',
    method: ResourceRequestMethod.Post
  })
  save: IResourceMethod<RequirementsModel, RequirementsModel>;

  @ResourceAction({
    path: '/requirements',
    method: ResourceRequestMethod.Patch
  })
  update: IResourceMethod<RequirementsModel, RequirementsModel>;
}
