import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MotivosDetailComponent } from './motivos-detail/motivos-detail.component';
import { MotivosListComponent } from './motivos-list/motivos-list.component';
import { MotivosRechazoRoutingModule } from './motivos-rechazo.routing';
import { AppCommonLibsModule } from '../common.module/common.module';
import { DirectivasModule } from '../directivas.module/directivas.module';
import { MotivosService } from './service/motivos.service';
import { MotivoRechazoResource } from './service/motivos-rechazo.resource';
import { RequirementsService } from './service/requirements.service';
import { RequirementsResource } from './service/requirements.resource';
import { TokenInterceptorService } from '../common.module/interceptors/token-interceptor.service';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import { FormalitiesService } from '../admin.formalities.module/service/formalities.service';
import { FormalitiesResource } from '../admin.formalities.module/service/formalities.resource';
import { FlujoService } from 'src/app/admin.bpm.module/configuracion.flujo.component/service/flujo.service';
import { FlujoResource } from '../admin.bpm.module/configuracion.flujo.component/service/resource/flujo.resource';



@NgModule({
  declarations: [
    MotivosDetailComponent, 
    MotivosListComponent    
  ],
  imports: [
    CommonModule,
    MotivosRechazoRoutingModule,
    AppCommonLibsModule,
    DirectivasModule
  ],
  providers: [
    MotivosService,
    MotivoRechazoResource,
    FormalitiesService,
    FormalitiesResource,
    RequirementsService,
    RequirementsResource,
    FlujoService,
    FlujoResource,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptorService,
      multi: true
    }
  ]
})
export class MotivosRechazoModule { }
