<div mdbModal #MotivoDetail="mdb-modal" class="modal fade overflow-auto" tabindex="-1" role="dialog"
     aria-labelledby="myBasicModalLabel" aria-hidden="true" [config]="{backdrop: true, ignoreBackdropClick: false}">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close pull-right" aria-label="Close" (click)="MotivoDetail.hide()">
          <span aria-hidden="true">×</span>
        </button>
        <h4 class="modal-title w-100">Motivo de Rechazo</h4>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-12">
            <form [formGroup]="requestForm">
              <div class="row justify-content-end">
                <div class="col-2">
                  <input class="form-check-input" type="checkbox" id="active" [(ngModel)]="motivoCopiado.activo"
                         [ngModelOptions]="{standalone: true}" [disabled]="disEdit" mdbInput>
                  <label class="form-check-label" for="active"><h6>Activo</h6></label>
                </div>
                <div class="col-lg-10 d-flex">
                  <button class="btn btn-primary ml-auto" *ngIf="motivoCopiado._id != undefined" (click)="enableEdition()">
                    <i class="fa fa-edit mr-1" aria-hidden="true"></i> Editar
                  </button>
                </div>
              </div>
              <div class="row no-gutters">
                <div class="col-12" [ngClass]="{disabled: disEdit}">
                  <div class="md-form">
                    <input mdbInput type="text" id="motivoClave" [(ngModel)]="motivoCopiado.clave" class="form-control"
                           [ngClass]="{disabled: disEditClave}" maxlength="10" formControlName="clave">
                    <label for="motivoClave" class="">Clave</label>
                    <div *ngIf="requestForm.controls['clave'].touched">
                      <div *ngIf="requestForm.controls['clave'].errors?.required"
                           class="text-danger animated fadeInDown fast-4 small">
                        El campo es obligatorio
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-12" [ngClass]="{disabled: disEdit}">
                  <div class="md-form mb-50">
                    <input mdbInput type="text" id="motivoDescripcion" [(ngModel)]="motivoCopiado.descripcion"
                           class="form-control mb-50" [ngClass]="{disabled: disEdit}" formControlName="descripcion">
                    <label for="motivoDescripcion">Descripción</label>
                    <div *ngIf="requestForm.controls['descripcion'].touched">
                      <div *ngIf="requestForm.controls['descripcion'].errors?.required"
                           class="text-danger animated fadeInDown fast-4 small">
                        El campo es obligatorio
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-12" [ngClass]="{disabled: disEdit}">
                  <div class="md-form mb-50">
                    <mdb-select [filterEnabled]="true" class="" [(ngModel)]="motivoCopiado.tramite_id"
                                [ngClass]="{disabled : disEdit}" 
                                formControlName="tramite"                                 
                                mdbInput [options]="tramite"
                                placeholder="Elige un trámite" label="Trámite"
                                (selected)="cDocumentos()">                                
                    </mdb-select>
                   <!--
                    <div *ngIf="requestForm.controls['tramite'].touched">
                      <div class="text-danger animated fadeInDown fast-4 small"
                            *ngIf="requestForm.controls['tramite'].errors?.required">
                        El campo es obligatorio
                      </div>
                    </div>
                    -->
                  </div>
                </div>
                <div class="col-12" [ngClass]="{disabled: disEdit}">
                  <div class="md-form mb-50">

                    <mdb-select [filterEnabled]="true" class="" [(ngModel)]="motivoCopiado.nombre_documento"
                                [ngClass]="{disabled : disEdit}" 
                                formControlName="documento"
                                mdbInput [options]="documento"
                                placeholder="Elige un documento" label="Documento">
                    </mdb-select>

                    <!--
                    <div *ngIf="requestForm.controls['documento'].touched">
                      <div *ngIf="requestForm.controls['documento'].errors?.required"
                           class="text-danger animated fadeInDown fast-4 small">
                        El campo es obligatorio
                      </div>
                    </div>
                   -->
                  </div>
                </div>
                
              </div>
            </form>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" (click)="MotivoDetail.hide();" class="btn btn-danger waves-light btn-md"
                aria-label="Close"
                mdbWavesEffect>
          Cancelar
        </button>
        <button class="btn btn-indigo waves-light btn-md white-text" [ngClass]="{disabled: disEdit}"
                (click)="saveMotivoRechazo();">
          <i class="far fa-save mr-1" aria-hidden="true"></i> Guardar
        </button>
      </div>
    </div>
  </div>
</div>
