/**
 * Created by OROJO on 10/01/2019.
 */

import {Injectable} from '@angular/core';
import {FormalitiesResource} from './formalities.resource';
import {FormalitiesConfigModel} from './formalitiesConfig.model';
import {CategoryModel} from './category.model';

@Injectable()
export class FormalitiesService {
  constructor(private formalititesResource: FormalitiesResource) {
  }

  getAllFormalities(): Promise<any[]> {
    return this.formalititesResource.getFormalites();
  }

  getOneFormalities(id: number): Promise<FormalitiesConfigModel> {
    return this.formalititesResource.getOneFormalitie({id: id});
  }

  saveFormalitites(formalities: FormalitiesConfigModel) {
    return this.formalititesResource.save(formalities);
  }

  updateFormalities(formalities: FormalitiesConfigModel) {
    return this.formalititesResource.update(formalities);
  }

  getAllCategories(): Promise<CategoryModel[]> {
    return this.formalititesResource.getCategories();
  }
}
