import { Injectable } from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
// tslint:disable-next-line:import-blacklist
import {Observable, throwError} from 'rxjs';
import { catchError } from 'rxjs/operators';
import {AuthServerProvider} from '../auth/auth-jwt.service';
import {AuthResource} from '../../main-login.component/service/auth.resource';
import { ToastService} from 'ng-uikit-pro-standard';
import {LoginModel} from '../../main-portal.component/login.component/service/login.model';
import {Router} from '@angular/router';

@Injectable()
export class TokenInterceptorService implements HttpInterceptor {
  public login: LoginModel = <LoginModel> {};
  public newToken: String;
  constructor(private auth: AuthServerProvider,
              private authResource: AuthResource,
              private toastMsg: ToastService,
              private router: Router) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
  console.log('Paso por el interceptor');
    const token: string = localStorage.getItem('mcp-id_token');
    if (token) {
      const tkn = this.auth.refreshToken2();
      console.log(tkn);
    }
  return next.handle(req);
   /* .pipe(catchError(err => {
    if ([401, 403].includes(err.status)) {
      if (err.error.message && err.error.message != '') {
        this.toastMsg.warning(err.error.message);
      }
      // auto logout if 401 or 403 response returned from api
      this.auth.logout();
    } else if (err.status == 400) {
      this.toastMsg.warning(err.error.message);
      const path = this.router.url;
/!*      if (path.indexOf('login') != -1) {
        this.router.navigate(['/app']);
      } else if (path.indexOf('portal') != -1) {
        this.router.navigate(['/portal']);
      }*!/
    }
    const error = (err && err.error && err.error.message) || err.statusText;
    return throwError(error);
  }));*/
  }
}
